import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { type FormikErrors, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  Trans,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import type { DraftContent } from 'ricos-content';
import type { RichContent } from 'ricos-schema';
import type { RicosEditorType } from 'ricos-editor';
import { safeJsonParse } from 'wix-rich-content-common';
import { EditorEventsContext } from 'wix-rich-content-editor-common/libs/EditorEventsContext';

import type { IFeedItem } from 'api/feed/types';
import type { ITopic } from 'api/topics/types';
import {
  selectCurrentUser,
  selectDidItemChange,
  selectDidItemCreate,
  selectDraft,
  selectGroupName,
  selectGroupSlugById,
} from 'store/selectors';

import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Button } from 'wui/Button';
import { InputDialog } from 'wui/InputDialog';
import { ButtonGroup } from 'wui/ButtonGroup';
import { DialogFooter } from 'wui/DialogFooter';
import { DialogContent } from 'wui/DialogContent';
import { DialogMobileHeader } from 'wui/DialogMobileHeader';
import { CardHeader } from 'wui/CardHeader';
import { EmptyState } from 'wui/EmptyState';
import { Spinner } from 'wui/Spinner';
import { Avatar } from 'wui/Avatar';
import { TextButton } from 'wui/TextButton';

import { RichContentEditor } from 'common/components/RichContent/loadable';

import { Link } from '../Link';
import { ProfileLink } from '../ProfileLink';
import { TopicsSelect } from './TopicsSelect';
import {
  FEED_ITEM_EDIT_DIALOG_CANCEL_BUTTON,
  FEED_ITEM_EDIT_DIALOG_OK_BUTTON,
  FEED_ITEM_EDIT_DIALOG_ROOT,
} from './dataHooks';

import classes from './FeedItemEditorDialog.scss';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  promoteGroup?: boolean;
  groupId?: string;
  item?: IFeedItem;
  forTopic?: ITopic;

  cancelLabel?: string;

  onCancel?(): void;
  onRefChange?(node: RicosEditorType | null): void;
}

interface IFeedItemForm {
  groupId: string;
  feedItemId?: string;
  topics: ITopic[];
  content?: DraftContent | RichContent;
}

export function FeedItemEditorDialog(props: IProps) {
  const {
    item,
    groupId,
    forTopic,
    onClose,
    onCancel,
    className,
    cancelLabel,
    onRefChange,
    promoteGroup,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { feed$ } = useController();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const $ricos = React.useRef<RicosEditorType | null>(null);
  const editor = React.useContext(EditorEventsContext);

  const feedItemId = item?.feedItemId;
  const contentJSON = item?.entity?.body?.content;
  const isNewPost = !feedItemId;
  const isRceNext = experiments.enabled('specs.groups.EnableRceNextOOI');

  const user = useSelector(selectCurrentUser);
  const didCreate = useSelector(selectDidItemCreate);
  const didUpdate = useSelector(selectDidItemChange(feedItemId));
  const groupName = useSelector(selectGroupName(groupId as string));
  const slug = useSelector(selectGroupSlugById(groupId as string));
  const draft = useSelector(selectDraft);

  const [focusTrap, setFocusTrap] = useState(true);

  const content = React.useMemo(() => {
    return contentJSON
      ? (safeJsonParse(contentJSON) as DraftContent)
      : undefined;
  }, [contentJSON]);

  const form = useFormik<IFeedItemForm>({
    onSubmit: handleSubmit,
    validate: handleValidate,
    enableReinitialize: true,
    initialValues: {
      content,
      feedItemId,
      groupId: groupId as string,
      topics: item?.entity.topics || (forTopic ? [forTopic] : []),
    },
  });

  useEffect(() => {
    if (!props.isOpen) {
      form.resetForm();
    }
  }, [props.isOpen]);

  useEffect(() => {
    const isCreated = isNewPost && didCreate;
    const isUpdated = !isNewPost && didUpdate;

    if (isCreated || isUpdated) {
      props.onClose();
    }
  }, [isNewPost, didUpdate, didCreate]);

  return (
    <InputDialog
      onClose={handleClose}
      focusTrap={!isMobile && focusTrap}
      hideCloseButton={isMobile}
      paperProps={{
        className: classes.paper,
        'data-hook': FEED_ITEM_EDIT_DIALOG_ROOT,
      }}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
      })}
      {...rest}
    >
      <Show if={isMobile}>
        <DialogMobileHeader>
          <TextButton
            onClick={handleCancel}
            data-hook={FEED_ITEM_EDIT_DIALOG_CANCEL_BUTTON}
            variant="secondary"
          >
            {cancelLabel || t('groups-web.discussion.new-post.mobile.back')}
          </TextButton>
          <TextButton
            loading={form.isSubmitting}
            onClick={form.submitForm}
            data-hook={FEED_ITEM_EDIT_DIALOG_OK_BUTTON}
            disabled={form.isSubmitting || !form.isValid || !form.dirty}
          >
            {t('groups-web.discussion.new-post.mobile.post')}
          </TextButton>
        </DialogMobileHeader>
      </Show>

      <Hide if={isMobile}>
        <CardHeader
          verticalAlign="middle"
          avatar={
            <ProfileLink profile={user}>
              <Avatar
                size="large"
                lettersLimit={2}
                name={user.name}
                src={user.imageUrl}
              />
            </ProfileLink>
          }
          title={<ProfileLink profile={user} />}
          titleProps={{ variant: 'p2-16' }}
          subtitle={
            !promoteGroup ? undefined : (
              <Trans
                values={{ groupName }}
                i18nKey="groups-web.discussion.new-post.create-post-in-group"
                components={[
                  <span />,
                  <Link state="group" params={{ slug }} />,
                ]}
              />
            )
          }
        />
      </Hide>

      <DialogContent
        scrollable={false}
        className={classes.content}
        disableSideGutters={isMobile}
      >
        <RichContentEditor
          autoFocus
          groupId={groupId}
          usage="NewPostModal"
          ref={handleEditorRef}
          onChange={handleContentChange}
          onBusyChange={form.setSubmitting}
          content={isNewPost ? draft : form.values.content}
          contentId={isNewPost ? undefined : form.values.feedItemId}
          placeholder={t('groups-web.discussion.new-post.placeholder')}
          fallback={<EmptyState bw variant="section" title={<Spinner />} />}
          className={cls(
            classes.editor,
            isRceNext ? classes.next : classes.legacy,
          )}
          modalSettings={{
            onModalOpen: handleRicosModalOpen,
            onModalClose: handleRicosModalClose,
          }}
        />
      </DialogContent>

      <Show if={isMobile}>
        <Box padding="SP0 SP5">
          <TopicsSelect
            isNewPost={isNewPost}
            value={form.values.topics}
            groupId={form.values.groupId}
            onChange={(topics) => form.setFieldValue('topics', topics)}
          />
        </Box>
      </Show>

      <Hide if={isMobile}>
        <DialogFooter align="space-between" verticalAlign="middle">
          <TopicsSelect
            isNewPost={isNewPost}
            value={form.values.topics}
            groupId={form.values.groupId}
            onChange={(topics) => form.setFieldValue('topics', topics)}
          />
          <ButtonGroup>
            <Button
              outlined
              variant="basic"
              data-hook={FEED_ITEM_EDIT_DIALOG_CANCEL_BUTTON}
              onClick={handleCancel}
            >
              {cancelLabel || t('groups-web.discussion.new-post.cancel')}
            </Button>
            <Button
              variant="basic"
              loading={form.isSubmitting}
              onClick={form.submitForm}
              data-hook={FEED_ITEM_EDIT_DIALOG_OK_BUTTON}
              disabled={form.isSubmitting || !form.isValid || !form.dirty}
            >
              {t('groups-web.discussion.new-post.publish')}
            </Button>
          </ButtonGroup>
        </DialogFooter>
      </Hide>
    </InputDialog>
  );

  function handleContentChange(content: DraftContent | RichContent) {
    const helpers = $ricos.current?.getContentTraits();

    if (!helpers) {
      return;
    }

    if (helpers.isEmpty) {
      form.setFieldValue('content', undefined);
    }

    if (isNewPost || helpers.isContentChanged) {
      form.setFieldValue('content', content);
    } else {
      form.setFieldValue('content', form.initialValues.content);
    }
  }

  function handleValidate(values: IFeedItemForm) {
    const helpers = $ricos.current?.getContentTraits();
    const isEmpty = helpers ? helpers.isEmpty : true;
    const errors: FormikErrors<IFeedItemForm> = {};

    if (isEmpty) {
      errors.content = 'true';
    }

    if (!values.groupId) {
      errors.groupId = 'true';
    }

    return errors;
  }

  function handleRicosModalOpen() {
    setFocusTrap(false);
  }

  function handleRicosModalClose() {
    setFocusTrap(true);
  }

  function handleEditorRef(node: RicosEditorType | null) {
    onRefChange?.(node);
    $ricos.current = node;
  }

  function handleSubmit(values: IFeedItemForm) {
    form.setSubmitting(true);

    const { feedItemId, groupId, topics } = values;
    const topicIds = topics.map((topic) => topic.id as string);

    editor
      .publish()
      .then(() =>
        $ricos.current?.getContentPromise({
          flush: true,
          publishId: feedItemId,
        }),
      )
      .then((content) => {
        if (feedItemId) {
          feed$.update(groupId, feedItemId, JSON.stringify(content), topicIds);
        } else {
          feed$.updateDraft(undefined);
          feed$.create(groupId, JSON.stringify(content), topicIds, true);
        }
      });
  }

  function handleCancel() {
    feed$.updateDraft(undefined);
    props.onCancel ? props.onCancel() : props.onClose();
  }

  async function handleClose() {
    const content = await $ricos.current?.getContent();

    if (isNewPost) {
      feed$.updateDraft(content);
    }

    props.onClose();
  }
}

FeedItemEditorDialog.displayName = 'FeedItemEditorDialog';
