import React from 'react';
import { take } from 'lodash';
import { useSettings } from '@wix/tpa-settings/react';

import type { IBadge } from 'api/badges/types';
import * as common from 'settings/common';

import { Wire } from 'wui/Wire';
import { Stack } from 'wui/Stack';
import { MemberBadge } from 'wui/MemberBadge';

import { MEMBER_BADGES_LIST } from './dataHook';
import classes from './MemberBadges.scss';

interface IProps {
  limit?: number;
  badges?: IBadge[];
}

export function MemberBadges(props: IProps) {
  const { limit = 2, badges } = props;

  const settings = useSettings();

  if (!badges?.length) {
    return null;
  }

  return (
    <Wire
      cssVarName="showMembersBadges"
      legacyFallback={settings.get(common.settings.showMembersBadges)}
    >
      <Stack
        gap="SP1"
        direction="horizontal"
        className={classes.root}
        data-hook={MEMBER_BADGES_LIST}
      >
        {take(badges, limit).map((badge) => (
          <MemberBadge key={badge.id} badge={badge} className={classes.badge} />
        ))}
      </Stack>
    </Wire>
  );
}

MemberBadges.displayName = 'MemberBadges';
